.App {
  text-align: center;
  font-family: "Helvetica Neue";
}

.App-header {
  font-family: "Helvetica Neue";
  background-color: #1e1e20;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5f5f7;
}

main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'Helvetica Neue';
  hyphens: auto;
  line-height: 1em;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-family: "Helvetica Neue";
  font-size: 55px;
  font-weight: 400;
  letter-spacing: -.01em;
  color: #f5f5f7;
  padding: 0 auto;
  margin: 0;
}
h2 {
  font-family: "Helvetica Neue";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: .04em;
  color: #f5f5f7;
  padding: 0 auto;
  margin: 0;
}
p {
  font-size: 14px;
}
a {
  color: #adadad;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: .2em;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #adadad;
}
ul {
  padding: 0;
  width: 100%;
  text-align: center;
}
img {
  pointer-events: none;
}
